import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RoomComponent } from './room/room.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { OrderModule } from 'ngx-order-pipe';
import {AngularFittextModule} from 'angular-fittext';



import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NewComponent } from './new/new.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';
import { AccountComponent } from './account/account.component';
import { PasswordComponent } from './password/password.component';
import { RunComponent } from './run/run.component';
 import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';


@NgModule({
  declarations: [
    AppComponent,
    RoomComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    TermsComponent,
        LoginComponent,
    PrivacyPolicyComponent,
    NewComponent,
    LoginComponent,
    AccountComponent,
    PasswordComponent,
    RunComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
NgbModule,
HttpClientModule,
    AngularFittextModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,
        AngularFireDatabaseModule,
                AngularFireAuthModule,
        FormsModule,
        ClipboardModule,
        OrderModule,
        OwlDateTimeModule, 
        OwlNativeDateTimeModule 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
