import { Component, OnInit } from '@angular/core';
import { AccountComponent } from '../account/account.component';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
		public pass : any;
		public pass1 : any;
				public pass2 : any;
						public enteremail : any;
								public register : any = false;
								public errormessage : any;

  constructor(public router:Router, public firebaseAuth:AngularFireAuth) { }

  ngOnInit() {
  }
  
  signup(email: string, password: string) {
    this.firebaseAuth
      .auth
      .createUserWithEmailAndPassword(email, password)
      .then(value => {
        console.log('Success!', value);
                console.log('user:', value.user.uid);
	  window.localStorage.setItem('uid',value.user.uid);

          this.router.navigateByUrl('/account', { state: { id: value.user.uid }});

      })
      .catch(err => {
        console.log('Something went wrong:',err.message);
        this.errormessage = err.message;
      });    
  }

  login(email: string, password: string) {
    this.firebaseAuth
      .auth
      .signInWithEmailAndPassword(email, password)
      .then(value => {
        console.log('Nice, it worked!');
        	  window.localStorage.setItem('uid',value.user.uid);
        	  window.localStorage.setItem('email',email);
                  this.router.navigateByUrl('/account', { state: { id: value.user.uid }});
      })
      .catch(err => {
        console.log('Something went wrong:',err.message);
                this.errormessage = err.message;

      });
  }


}
