import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
public loggedin: any =  false;
public uid: any;
  constructor(public router:Router) { }

  ngOnInit() {
this.checkloggedIn()
  }

ngAfterViewChecked(){
	this.checkloggedIn();
}
checkloggedIn(){
		  this.uid = window.localStorage.getItem('uid');

if(this.uid){
	this.loggedin = true;
}
}
logout(){
	window.localStorage.clear();
	  this.router.navigateByUrl('/', { replaceUrl: true });
this.loggedin = false;
}
}
