import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { TermsComponent } from './terms/terms.component';
import { NewComponent } from './new/new.component';
import { RoomComponent } from './room/room.component';
import { LoginComponent } from './login/login.component';
import { AccountComponent } from './account/account.component';
import { PasswordComponent } from './password/password.component';
import { RunComponent } from './run/run.component';


import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';


const routes: Routes = [
	{ path: 'home', component: HomeComponent },
		{ path: 'privacy-policy', component: PrivacyPolicyComponent },
			{ path: 'terms', component: TermsComponent },
						{ path: 'login', component: LoginComponent },
						{ path: 'account', component: AccountComponent },
												{ path: 'forgotpass', component: PasswordComponent },
						{ path: 'new', component: NewComponent },
												{ path: 'go', component: NewComponent },
						{ path: 'room/:pin', component: RoomComponent },
												{ path: 'run/:pin/:code', component: RunComponent },
																								{ path: 'run/:pin', component: RunComponent },
	{ path: '', redirectTo: '/home', pathMatch: 'full' },
	{ path: ':pin', redirectTo: 'room/:pin', pathMatch: 'full' }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
