import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-run',
  templateUrl: './run.component.html',
  styleUrls: ['./run.component.scss']
})
export class RunComponent implements OnInit {
	public pin : any;
	public access : any = '';
	public errormessage: any = '';

  constructor(public route : ActivatedRoute, public router :Router) {
	  	  	  this.pin = this.route.snapshot.paramMap.get('pin');
	  	  	  	  	  	  this.access = this.route.snapshot.paramMap.get('code');
if(this.access){
	this.checkCode();
}
	   }

  ngOnInit() {
  }
  
  checkCode(){
	  if(this.access == this.hash(this.pin)){
		  console.log('matches');
		  	  window.localStorage.setItem(this.pin,'roomowner'+this.pin);
  this.router.navigateByUrl('/room/'+this.pin, { replaceUrl: true });

	  }else{
		  this.errormessage = 'Access Code Invalid';
		  this.access = '';
	  }
  }

hash(s){
    /* Simple hash function. */
    var z = '';
    var a = 1, c = 0, h, o;
    if (s) {
        a = 0;
        /*jshint plusplus:false bitwise:false*/
        for (h = s.length - 1; h >= 0; h--) {
            o = s.charCodeAt(h);
            a = (a<<6&268435455) + o + (o<<14);
            c = a & 266338304;
            a = c!==0?a^c>>21:a;
        }
    }
    z = a.toString();
    z = z.substring(0, 6);
 
    var x = 0;
    var y = '';
    var b = '';
    for (var i = 0; i < z.length; i++) {
  x = parseInt(z.charAt(i));
  y = 'agndobgrpfbysl'.charAt(x);
  b = b+y;

}
    return b;
}


}
