import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { map } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { ExportToCsv } from 'export-to-csv';





@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
public uid: any;
public ref: any;
public task:any;
public uploadProgress: any;
public edits: any = false;
public user : any;
public oldmobileURL: any;
public oldlogoURL: any;
public olddownloadURL: any;
public downloadURL: any;
public logoURL: any;
public rooms: any;
public newbg: any = false;
public newlogo: any = false;
public newmobile: any = false;



  constructor(public router : Router,private afStorage: AngularFireStorage, public db:AngularFireDatabase) { 
	  this.uid =window.localStorage.getItem('uid');
	  
	  if(!this.uid){
		  	          this.router.navigateByUrl('/login');

	  }
	  
	   let rooms =  this.db.list('/rooms', ref => ref.orderByChild('uid').equalTo(this.uid)).snapshotChanges().pipe(map(changes =>
	  changes.map(c=>({key:c.payload.key, ...c.payload.val()}))
	  )
	  );
	  console.log(rooms);
	  rooms.subscribe( res =>{
		  this.rooms = res;
		  });
	  
	  	  let user =  this.db.object('/users/'+this.uid).snapshotChanges();
	  	  	  user.subscribe( res =>{
		  this.user = res.payload.val();
		  console.table(this.user);
		  if(this.user.logo){
			  this.oldlogoURL = this.user.logo;
			  	  window.localStorage.setItem('logo',this.oldlogoURL);

		  }
		  		  if(this.user.mobile){
			  this.oldmobileURL = this.user.mobile;
			  	  window.localStorage.setItem('mobile',this.oldmobileURL);

		  }
		  		  if(this.user.logo){
			  this.olddownloadURL = this.user.bg;
			  			  	  window.localStorage.setItem('bg',this.olddownloadURL);

		  }
		  });

  }

  ngOnInit() {
    
  }
  
  upload(event) {
  const randomId = Math.random().toString(36).substring(2);
  this.ref = this.afStorage.ref(randomId);
  this.task = this.ref.put(event.target.files[0]);
  console.log(this.task);
  	  this.olddownloadURL =false;
  this.uploadProgress = this.task.percentageChanges();
    this.task.snapshotChanges().pipe(
        finalize(() => {
	        	        var bg = this.ref.getDownloadURL();
	                bg.subscribe(url=>{this.newbg = url; this.saveBg();})
	        })
     )
    .subscribe();
this.edits = true;
}
  uploadLogo(event) {
	  this.oldlogoURL =false;
  const randomId = Math.random().toString(36).substring(2);
  this.ref = this.afStorage.ref(randomId);
  this.task = this.ref.put(event.target.files[0]);
  console.log(this.task);
  this.uploadProgress = this.task.percentageChanges();
    this.task.snapshotChanges().pipe(
        finalize(() => {
	        	        var logo = this.ref.getDownloadURL();
	                logo.subscribe(url=>{this.newlogo = url; this.saveLogo();})
	        })     )
    .subscribe();
this.edits = true;
}

saveLogo(){
					  	  window.localStorage.setItem('logo',this.newlogo);
		const itemRef = this.db.object('users/'+this.uid);
itemRef.update({ logo: this.newlogo});
}

  uploadMobile(event) {
	  this.oldmobileURL =false;
  const randomId = Math.random().toString(36).substring(2);
  this.ref = this.afStorage.ref(randomId);
  this.task = this.ref.put(event.target.files[0]);
  console.log(this.task);
  this.uploadProgress = this.task.percentageChanges();
    this.task.snapshotChanges().pipe(
        finalize(() => {
	        	        var mobile = this.ref.getDownloadURL();
	                mobile.subscribe(url=>{this.newmobile = url; this.saveMobile();})
	        })     )
    .subscribe();
this.edits = true;
}

saveMobile(){
					  	  window.localStorage.setItem('mobile',this.newmobile);
		const itemRef = this.db.object('users/'+this.uid);
itemRef.update({ mobile: this.newmobile});
}

saveBg(){
					  	  window.localStorage.setItem('bg',this.newbg);
		const itemRef = this.db.object('users/'+this.uid);
itemRef.update({ bg: this.newbg});
}

start(){
	          this.router.navigateByUrl('/new');

}
goDraw(pin){
	alert(pin);
	          this.router.navigateByUrl('/room/'+pin);

}
hash(s){
    /* Simple hash function. */
    var z = '';
    var a = 1, c = 0, h, o;
    if (s) {
        a = 0;
        /*jshint plusplus:false bitwise:false*/
        for (h = s.length - 1; h >= 0; h--) {
            o = s.charCodeAt(h);
            a = (a<<6&268435455) + o + (o<<14);
            c = a & 266338304;
            a = c!==0?a^c>>21:a;
        }
    }
    z = a.toString();
    z = z.substring(0, 6);
 
    var x = 0;
    var y = '';
    var b = '';
    for (var i = 0; i < z.length; i++) {
  x = parseInt(z.charAt(i));
  y = 'agndobgrpfbysl'.charAt(x);
  b = b+y;

}
    return b;
}
exportCSV(data){
	data = Object.values(data);
	console.log(data);
 const options = { 
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true, 
    showTitle: true,
    title: 'swagit-competition-entries',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };

const csvExporter = new ExportToCsv(options);

csvExporter.generateCsv(data);
}
vrfyhash(s){
    /* Simple hash function. */
    var a = 1, c = 0, h, o;
    if (s) {
        a = 0;
        /*jshint plusplus:false bitwise:false*/
        for (h = s.length - 1; h >= 0; h--) {
            o = s.charCodeAt(h);
            a = (a<<6&268435455) + o + (o<<14);
            c = a & 266338304;
            a = c!==0?a^c>>21:a;
        }
    }
    return String(a);
}
}
