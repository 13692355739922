import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
	public email : any;
	public message: any = false;

  constructor(public firebaseAuth:AngularFireAuth) { }

  ngOnInit() {
  }
  
  reset(){
	        this.message = 'A password reset email has been sent to '+this.email+'.';
let t = this;
  this.firebaseAuth.auth.sendPasswordResetEmail(this.email)
    .then(function() {
      t.email= '';
      })
    .catch(function(error) {
            t.email = '';

    });
}
}
