import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
public pin : any;
public stats: any;
  constructor(private router:Router, public db:AngularFireDatabase) {
	      let stats = this.db.list('stats').valueChanges();
	      stats.subscribe( res =>{
		      console.log(res);
		      this.stats = res;
		      	      console.log(this.stats);

	      });
	      

	   }

  ngOnInit() {
	  
  }

join(){
	  this.router.navigateByUrl('/room/'+this.pin);
}
}
