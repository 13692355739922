import { Component } from '@angular/core';
import { Router }  from "@angular/router";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
public page : any;
	  constructor(public router:Router) { 
		  
		  this.page = this.router.url.split("/");
		  console.log(this.page);
		  
	  }

  title = 'swagit';
  
  onInit(){
	  
  }
}
