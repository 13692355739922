import { Component, OnInit, HostListener  } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map, finalize, first} from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import {interval} from "rxjs";
import ConfettiGenerator from "confetti-js";
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';





@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit {
	public pin : any;
  public roomRef: any;
  public room: any;
  public myname: any;
  public editmode: any = false;
    public editbar: any = false;

public entername: any;
public enteremail: any;
public count: any = 0;
public names: any = [];
public mykey;any;
public namecount: any = 1;
public dup: any;
public namelist: any = [];
public drawname: any;
public loaded : any = false;
public owner : any;
public debug : any;
public played : any = false;
public editquestions: any;
public uid: any;
public newlogo: any = false;
public newbg: any = false;
public newmobile: any = false;
public task: any;
public ref: any; 
public uploadProgress: any;
public setbg: any;
public setlogo: any;
public iframe : any = false;
public heartbeat: any;
public consent = false;
public emailunique = true;
public dead = false;
public emailvalid = true;
public gotFocus = 99;
public questions: any =  ['','','',''];


    @HostListener("window:beforeunload",["$event"])
    clearLocalStorage(event){
	    if(this.myname != this.owner && this.debug != 1 && this.room.drawtype!=1){
        this.exit();
        }else{
	       	    if(this.myname == this.owner && this.debug != 1 && this.uid.length < 8 && this.room.drawtype!=1){
        this.closeDraw();
        } 
        }
    }
  constructor(public db:AngularFireDatabase, public route:ActivatedRoute, public afAuth :AngularFireAuth,public router:Router, private afStorage: AngularFireStorage,public http: HttpClient) { 
	  	  this.pin = this.route.snapshot.paramMap.get('pin');
	  	  this.myname = window.localStorage.getItem(this.pin);
	  	  	  	  this.debug = window.localStorage.getItem('debug');
this.uid = window.localStorage.getItem('uid');
this.loadHeartBeat();
if(!this.uid){
	this.uid = '999999999';
}

	  	  this.owner = 'roomowner'+this.pin;
	  	 console.log(this.myname);
console.log(this.owner); 


	  let rooms =  this.db.list('/rooms', ref => ref.orderByChild('pin').equalTo(this.pin)).snapshotChanges().pipe(map(changes =>
	  changes.map(c=>({key:c.payload.key, ...c.payload.val()}))
	  )
	  );
	  console.log(rooms);
	  rooms.subscribe( res =>{
		  console.log(res);
		  this.room = res[0];
		  if(res.length == 0){
			  this.dead = true;
		  }
		  		  if(this.room.uid == this.uid){
			  this.myname = 'roomowner'+this.pin
			  console.log(this.myname);
		  }
		  if(!this.room.prize){
			  this.room.prize ='';
		  }
		  		  if(!this.room.date){
			  		  let date = Date.now();
			  this.room.date = date;
		  }
		  		  if(!this.room.title){
			  this.room.title ='';
		  }
		  		  if(!this.room.url){
			  this.room.url ='';
		  }
		  		  		  if(!this.room.winnerno){
			  this.room.winnerno =1;
		  }
		  		  		  if(!this.room.logo){
			  this.room.logo ='';
		  }
		  		  		  if(!this.room.bg){
			  this.room.bg ='';
		  }
		  		  		  if(!this.room.mobile){
			  this.room.mobile ='';
		  }
		  		  this.loaded = true;
		  if(this.room.status == 2 && this.myname){
			  	var confettiSettings = { target: 'my-canvas', max: 160, speed: 50, rotate: true };
var confetti = new ConfettiGenerator(confettiSettings);
confetti.render();
this.playAudio();
this.loadUrl();
		  }
		  if(!this.room){
			  confetti.clear();

		  }
		  this.names = this.room['names'];
		  if(!this.room.uid){
			  this.room.uid='guest';
		  }
		  console.log(this.room.uid);
		  let t = this;
		  this.namelist = [];
		  if(this.names){
		  this.count = Object.keys(this.names).length;
}
		  if(this.count > 0){
		  Object.keys(this.names).forEach(function(key) {

if(t.room.drawtype == 1){
	t.namelist.push({key: key, name : t.names[key]['name'], email : t.names[key]['email']});

}else{
t.namelist.push({key: key, name : t.names[key]['name']});
}

});
console.table(this.names);
}
	  })

  }

  ngOnInit() {

  }
  
  removeQ(key){
	    	console.log(key);
		const itemRef = this.db.object('rooms/'+this.room['key']+'/questions/'+key);
	itemRef.remove();
	this.gotFocus = 99;
  }

saveEdits(){
		  	  const itemRef = this.db.object('rooms/'+this.room['key']);
		  	  var logo = this.room.logo;
		  	  		  	  var bg = this.room.bg;
		  	  		  	  		  	  		  	  var mobile = this.room.mobile;
		  	  		  	  
		  	  if(this.newlogo){
		  	  logo = this.newlogo;
		  	  }
		  	  		  	  if(this.newbg){
		  	  bg = this.newbg;
		  	  }
		  	  		  	  		  	  if(this.newmobile){
		  	  mobile = this.newmobile;
		  	  }
		  	  itemRef.update({ prize: this.room.prize, url:this.room.url, bg: bg, logo:logo, title:this.room.title, mobile:mobile, winnerno: this.room.winnerno, drawtype:this.room.drawtype, date:this.room.date});
		  	  this.editmode = false;

}
  saveName(){
let t = this;
this.dup = 0;
if(this.count > 0){
Object.keys(this.names).forEach(function(key) {

  console.log(key, t.names[key]);
  if(t.names[key]['name'].toLowerCase() == t.entername.toLowerCase()){
	  t.dup = 1;
  }

});
}

	  if(this.dup == 0) {
let data = {};
if(this.room.drawtype == 1){
	data = {
		name : this.entername,
		email : this.enteremail
	};
	let x = 0;
	let t = this;
	while(x < 20){
		if(t.questions[x]){
		data['response'+x] = t.questions[x]
		}
		x++;
	}
}else{
		data = {
		name : this.entername
		};
}
	  console.log(data);
	  
	  	  const itemRef = this.db.list('rooms/'+this.room['key']+'/names');
itemRef.push(data).then(ref=>{
    console.log(ref.key);
    //pass the key to the function
this.mykey = ref.key;
});
window.localStorage.setItem(this.pin,this.entername);
this.myname = this.entername;
this.incrementUsers();
}else{
		this.entername = this.entername.replace(this.namecount,'');
		this.namecount++;
	this.entername= this.entername+this.namecount.toString();
	this.saveName();
}
  }
  
  checkEmail(){
	  let t = this;
	  this.emailunique = true;
	  if(this.count > 0){
Object.keys(this.names).forEach(function(key) {

  console.log(key, t.names[key]);
  if(t.names[key]['email'].toLowerCase() == t.enteremail.toLowerCase()){
	  t.emailunique = false;
  }

});
}
this.emailvalid = this.emailIsValid(this.enteremail);
}
exit(){
	if(this.room.drawtype != 1){
	window.localStorage.clear();
	this.myname = '';
	if(this.room){
	const itemRef = this.db.object('rooms/'+this.room['key']+'/names/'+this.mykey);
itemRef.remove();
}
}

if(this.room.url){
	window.open(this.room.url,'_blank"');
	  this.router.navigateByUrl('/home');

}else{
  this.router.navigateByUrl('/home');
  }

}
closeDraw(){
	console.log('draw'+this.room.drawtype);
		if(this.room.drawtype == 0){
	if(this.uid == '999999999'){
	window.localStorage.clear();
	this.myname = '';
	const itemRef = this.db.object('rooms/'+this.room['key']);
itemRef.remove();
  this.router.navigateByUrl('/home');	
  }else{
	  	this.myname = '';
	const itemRef = this.db.object('rooms/'+this.room['key']);
//itemRef.remove();
}
  }
  
   this.router.navigateByUrl('/account');	
}
deleteDraw(){

	  	this.myname = '';
	const itemRef = this.db.object('rooms/'+this.room['key']);
itemRef.remove();
   this.router.navigateByUrl('/account');	
}
hack(val) {
  return Array.from(val);
}
startDraw(){
	this.room.status = 1;
	const itemRef = this.db.object('rooms/'+this.room['key']);
itemRef.update({ status: 1 });
	this.drawname = this.namelist[this.randomInt()].name;
	let t = this;
	let count = 0;
const subscription = interval(900).subscribe(()=>{
t.drawname = t.namelist[t.randomInt()].name;
count++;
t.playHeartBeat();
if(count == 10){
	subscription.unsubscribe();
	this.faster();
}
	});
}

faster(){
		let t = this;
	let count = 0;
const subscription = interval(300).subscribe(()=>{
t.drawname = t.namelist[t.randomInt()].name;
t.playHeartBeat();
count++;
if(count == 20){
	subscription.unsubscribe();
	this.winner();
}
	});
}
winner(){
	this.room.status = 2;
	let i = 0;
	let x = 0;
	let data = {};
	let winners = [];
	let winnernames = [];
	while (i < this.room.winnerno) {
		x = this.randomInt();
		console.log(x);
		if(this.room.drawtype == 1){
		data={
			name: this.namelist[x].name,
			email: this.namelist[x]['email']
		};
		}else{
					data={
			name: this.namelist[x].name
		};
		}

let obj = [];
obj = winners.find(a => a.email == this.namelist[x]['email']);
console.log(obj);
		if(!winnernames.includes(this.namelist[x].name)){
		winners.push(data);
  i++;
  		winnernames.push(this.namelist[x].name);
  }
  
}
console.log(winners);
	let t = this;
	this.drawname = this.namelist[this.randomInt()].name;
		const itemRef = this.db.object('rooms/'+this.room['key']);
itemRef.update({ status: 2 });
		const itemRef2 = this.db.object('rooms/'+this.room['key']+'/winners');
itemRef2.set(winners).then(res=>{
	if(t.room.drawtype==1){
	t.sendEmails();
}
	});	
	var confettiSettings = { target: 'my-canvas', max: 160, speed: 50, rotate: true };
var confetti = new ConfettiGenerator(confettiSettings);
confetti.render();
this.playAudio();

}
playAudio(){
	if(!this.played){
  let audio = new Audio();
  audio.src = "/assets/crowd.mp3";
  audio.load();
  audio.play();
  this.played = true;
  }
}

playHeartBeat(){
  this.heartbeat.play();
}
loadHeartBeat(){
	  this.heartbeat = new Audio();
  this.heartbeat.src = "/assets/heartbeat.mp3";
  this.heartbeat.load();
}
randomInt() { // min and max included 
    var min = Math.ceil(0);
    var max = Math.floor(this.count-1);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

endDraw(){
	
}

  upload(event) {
  const randomId = Math.random().toString(36).substring(2);
  this.ref = this.afStorage.ref(randomId);
  this.task = this.ref.put(event.target.files[0]);
  console.log(this.task);
  this.uploadProgress = this.task.percentageChanges();
    this.task.snapshotChanges().pipe(
        finalize(() => {
	        var bg = this.ref.getDownloadURL();
	                bg.subscribe(url=>{this.newbg = url})

	        })
     )
    .subscribe();
}
  uploadLogo(event) {
  const randomId = Math.random().toString(36).substring(2);
  this.ref = this.afStorage.ref(randomId);
  this.task = this.ref.put(event.target.files[0]);
  console.log(this.task);
  this.uploadProgress = this.task.percentageChanges();
    this.task.snapshotChanges().pipe(
        finalize(() => {
	        var logo = this.ref.getDownloadURL();
	                logo.subscribe(url=>{this.newlogo = url})

	        })
     )
    .subscribe();
}

uploadMobile(event) {
  const randomId = Math.random().toString(36).substring(2);
  this.ref = this.afStorage.ref(randomId);
  this.task = this.ref.put(event.target.files[0]);
  console.log(this.task);
  this.uploadProgress = this.task.percentageChanges();
    this.task.snapshotChanges().pipe(
        finalize(() => {
	        var logo = this.ref.getDownloadURL();
	                logo.subscribe(url=>{this.newmobile = url})

	        })
     )
    .subscribe();
}


loadUrl(){
	
	let t = this;
	
	
	if(this.room.url && this.room.winner != this.myname ){
		
		    setTimeout(function(){
t.iframe = true;
    }, 3000);

	}
}
saveQ(){
	console.log(this.room.questions);
		const itemRef = this.db.object('rooms/'+this.room['key']+'/questions');
	itemRef.update(this.room.questions);
	this.gotFocus = 99;
}
addQuestion(){
	this.saveEdits();
	let data = {title:'',type: 0,options:'',multi:0}
	const itemRef = this.db.list('rooms/'+this.room['key']+'/questions');
	itemRef.push(data);
	this.editmode = true;
	this.editbar = true;
}

incrementUsers(){
this.db.object('stats/users')
  .query.ref.transaction((users => {
    if (users === null) {
        return users = 1;
    } else {
        return users + 1;
    }
}))}

addCheck(e,i,o){
	if(e.target.checked){
	console.log(e);
	console.log(i);
	//this['question'+i].push(o);
	if(this.questions[i] ==''){
		this.questions[i] = o;
	}else{
	this.questions[i] = this.questions[i] + ','+o;
	}
	}else{
						this.questions[i] = this.questions[i].replace(o+",", "");

		this.questions[i] = this.questions[i].replace(o, "");
	}
		console.log(this.questions);
}
updateAnswers(ev,i){
	console.log(ev);
	this.questions[i] = ev.target.value;
}

hash(s){
    /* Simple hash function. */
    var a = 1, c = 0, h, o;
    if (s) {
        a = 0;
        /*jshint plusplus:false bitwise:false*/
        for (h = s.length - 1; h >= 0; h--) {
            o = s.charCodeAt(h);
            a = (a<<6&268435455) + o + (o<<14);
            c = a & 266338304;
            a = c!==0?a^c>>21:a;
        }
    }
    return String(a);
}

split(opts){
	console.log(opts);
	return opts.split("|");
}
checked(){
	if(this.consent){
		this.consent = false;
	}else{
		this.consent = true;
	}
}

valid(){
	if(this.consent && this.enteremail && this.entername && this.emailunique && this.emailvalid){
		return true;
	}else{
		return false;
	}
}

emailIsValid (email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

sendEmails(){
	let t = this;
	if(t.room.prize ==''){
		t.room.prize = 'Swag';
	}
	this.room.winners.forEach(w =>{
		t.sendWinnerEmail(w.name,w.email, t.room.prize, t.room.owneremail);
		});
	}

sendWinnerEmail(name,useremail,title,owner){
let subject = '🎁 Congratulations '+name+' You have WON the '+title+' competition.';
let message = "Dear "+name+",<br /><br />Congratulations on winning "+title+" on swagit.io. <br /><br /> The competition owner has been notified that you have won and will be in touch soon to arrange shipment of your prize, "+title+". <br /><br /> If you have any questions regarding the delivery of your prize, please reply directly to the competition owner by replying to this e-mail. <br /><br /> If you enjoyed using Swagit, why not share your news with your friends <a href='http://twitter.com/share?text=I won a prize by entering a competition on swagit.io&url=https://www.swagit.io'>Twitter</a> |  <a href='https://www.facebook.com/sharer/sharer.php?u=https://www.swagit.io'>Facebook</a> |  <a href='https://www.linkedin.com/shareArticle?mini=true&url=https://www.swagit.io&title=I won a prize by entering a competition on swagit.io!'>LinkedIn</a> <br /> <br /> Thank you for using Swagit <br /><br /> The Swag Office<br /><br />Please note that swagit.io is not responsible for the distribution or fulfilment of prizes won on the platform. If you have any questions, please contact the competition owner "+owner+".";


const email = {
  to: useremail,
  reply: owner,
  subject: subject,
  text: message,
};

	const itemRef = this.db.list('emails');
itemRef.push(email);


}
}
