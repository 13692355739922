import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent implements OnInit {
	public data: any;
	public pin : any;
	public previous: any;

  constructor(public db : AngularFireDatabase, private router:Router) { 
	  this.data = db;
  }

  ngOnInit() {

	  this.pin = getRandomInt(1000,9999);
	  this.pin = this.pin.toString();
	  const itemRef = this.data.list('rooms');
	  window.localStorage.setItem(this.pin,'roomowner'+this.pin);
	  let logo =window.localStorage.getItem('logo');
	  let bg = window.localStorage.getItem('bg');
	  let uid =window.localStorage.getItem('uid');
	  	  let mobile = window.localStorage.getItem('mobile');
	  	  	  	  let email =window.localStorage.getItem('email');
	  	  console.log(bg);
	  	  if(!mobile){
		  	  mobile = '';
	  	  }
	  	  	  	  if(!logo){
		  	  logo = 'null';
	  	  }
	  	  	  	  if(!bg){
		  	  bg = '';
	  	  }

	  this.incrementDraws();
	  if(bg && logo && mobile){
		itemRef.push({ pin: this.pin,
	status: 0,
	logo:logo,
	bg:bg,
	mobile:mobile,
	uid:uid,
	drawtype: 0,
	owneremail:email
});  
	  }else{
itemRef.push({ pin: this.pin,
	status: 0,
		drawtype: 0
});
}
  this.router.navigateByUrl('/room/'+this.pin, { replaceUrl: true });
  }


incrementDraws(){
this.db.object('stats/draws')
  .query.ref.transaction((draws => {
    if (draws === null) {
        return draws = 1;
    } else {
        return draws + 1;
    }
}))}

}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}